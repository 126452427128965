import React, { ReactElement, useEffect } from 'react';
import { LanguageWithCountries } from 'lib/languages';
import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill';

export default function Languages(props: {
  languages: LanguageWithCountries[];
}): ReactElement {
  useEffect(() => {
    polyfillCountryFlagEmojis();
  }, []);

  return (
    <div id="languages" className="py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="text-center">
          <h2 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Supports {props.languages.length} different languages
          </h2>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 mx-auto">
            TranscribeKit can automatically transcribe audio and video in the
            following languages
          </p>
        </div>

        <div className="mt-10">
          <ul className="grid grid-cols-2 gap-x-5 gap-y-5 md:grid-cols-4">
            {props.languages
              .filter((l) => l.countries.length > 3)
              .map((language) => (
                <div key={language.name} className="relative text-center">
                  <li className="text-lg leading-6 font-medium text-gray-900">
                    <span className="mr-3">{language.name}</span>
                    <br />
                    {language.countries.map((country) => (
                      <Flag key={country.code} countryCode={country.code} />
                    ))}
                  </li>
                </div>
              ))}
          </ul>
          <ul className="mt-4 flex flex-wrap">
            {props.languages
              .filter((l) => l.countries.length <= 3)
              .map((language) => (
                <div key={language.name} className="relative text-center">
                  <li className="p-3 text-lg leading-6 font-medium text-gray-900">
                    {language.countries.map((country) => (
                      <Flag key={country.code} countryCode={country.code} />
                    ))}
                    <span>{language.name}</span>
                  </li>
                </div>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

function Flag(props: { countryCode: string }) {
  const codePoints = props.countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt(0));

  const flagEmoji = String.fromCodePoint(...codePoints);

  return (
    <div
      className="inline-flex w-6 mr-2"
      style={{ fontFamily: 'Twemoji Country Flags', fontSize: '1.4rem' }}
      aria-hidden="true"
    >
      {flagEmoji}
    </div>
  );
}
