import React, { ReactElement } from 'react';
import Link from 'next/link';

export default function Footer(): ReactElement {
  return (
    <>
      <div className="w-max mx-auto pt-4">
        <Link href="/terms">
          <a className="px-4 pb-10 font-bold text-blue-500 hover:text-blue-700">
            Terms and Conditions
          </a>
        </Link>
      </div>
      <div className="w-max mx-auto pb-4">
        <Link href="/privacy">
          <a className="px-4 pb-10 font-bold text-blue-500 hover:text-blue-700">
            Privacy Policy
          </a>
        </Link>
      </div>
      <p className="px-4 pb-10 text-center text-sm text-gray-600">
        Google Drive is a trademark of Google Inc. Use of this trademark is
        subject to Google Permissions.
      </p>
    </>
  );
}
