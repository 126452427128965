import React, { ReactElement } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { ChatAlt2Icon } from '@heroicons/react/solid';
import Link from 'next/link';

export default function SiteHead(props: {
  title?: string;
  description?: string;
  language?: string;
  availableLanguages?: string[];
}): ReactElement {
  const title =
    'TranscribeKit - ' + (props.title || 'Transcribe files in Google Drive');
  const description =
    props.description ||
    'Automatically transcribe audio and video directly from Google Drive using our speech-to-text add-on and save hours of manual transcription work';
  const path = useRouter().pathname;
  const canonicalUrl = 'https://www.transcribekit.com' + path;

  const pathWithoutLang =
    props.language && props.language !== 'en'
      ? path.substring(props.language.length + 1)
      : path;

  return (
    <>
      <Head>
        <title>{title}</title>
        {process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview' && (
          <meta name="robots" content="noindex" />
        )}
        <meta name="description" content={description} />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content="https://transcribekit.com/og-image.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="transcribekit.com" />

        <link
          rel="icon"
          type="image/svg+xml"
          href="/speaker_notes_black_48dp.svg"
        />
        <link rel="alternate icon" href="/favicon.ico" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />
        <link rel="canonical" href={canonicalUrl} />

        {props.availableLanguages?.map((lang) => (
          <link
            key={lang}
            rel="alternate"
            hrefLang={lang}
            href={
              'https://www.transcribekit.com' +
              (lang !== 'en' ? '/' + lang : '') +
              pathWithoutLang
            }
          />
        ))}
      </Head>

      {path !== '/contact' && (
        <Link href="/contact">
          <a className="fixed right-5 bottom-5 h-14 w-14 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-full text-white bg-blue-600 hover:bg-blue-70 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            <ChatAlt2Icon
              className="h-full w-full text-white"
              aria-hidden="true"
            />
          </a>
        </Link>
      )}

      <Script
        data-domain="transcribekit.com"
        src="https://plausible.io/js/plausible.outbound-links.js"
      />
    </>
  );
}
