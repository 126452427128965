import React, { ReactElement } from 'react';
import Image from 'next/image';
import Link from 'next/link';

export default function HowItWorks(): ReactElement {
  return (
    <div id="how-it-works" className="py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mb-10 lg:text-center">
          <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">
            How it works
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Transcribe directly from Google Drive
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            Install the Google Drive add-on, upload your file and create a
            transcript. Automatic speech-to-text will handle the rest
          </p>
          <p className="mt-4 max-w-2xl text-xl lg:mx-auto">
            <Link href="/how-to-transcribe">
              <a className="underline">
                Click here for the full step-by-step guide
              </a>
            </Link>
          </p>
        </div>

        <div className="flex justify-center">
          <Image
            src="/how_it_works.gif"
            alt="Demonstration of the add-on"
            quality={100}
            layout="intrinsic"
            width={800}
            height={500}
          />
        </div>
      </div>
    </div>
  );
}
