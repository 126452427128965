export const pricePerHour = 5;

export const priceLevels = [
  {
    hours: 1,
    product: parseInt(process.env.NEXT_PUBLIC_PRODUCT_ID_1_HOUR),
  },
  {
    hours: 5,
    discountPercent: 12,
    product: parseInt(process.env.NEXT_PUBLIC_PRODUCT_ID_5_HOURS),
  },
  {
    hours: 10,
    discountPercent: 22,
    product: parseInt(process.env.NEXT_PUBLIC_PRODUCT_ID_10_HOURS),
  },
];

export const discountedPrice = (priceLevel: typeof priceLevels[0]): number =>
  (pricePerHour * (100 - (priceLevel.discountPercent || 0))) / 100;

export const formatPrice = (price: number): string =>
  '$' + ((price * 100) % 100 == 0 ? price : price.toFixed(2));
