import React, { ReactElement } from 'react';
import {
  discountedPrice,
  formatPrice,
  priceLevels,
  pricePerHour,
} from 'lib/prices';

export default function PricingOnly(): ReactElement {
  return (
    <>
      <div className="p-4 flex flex-col sm:flex-row items-center justify-center">
        {priceLevels.map((priceLevel) => (
          <div
            key={priceLevel.hours}
            className="w-52 sm:w-full max-w-xs m-3 flex-1 flex flex-col rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 relative"
          >
            <div className="w-full px-4 pt-4 pb-2 sm:pt-8 sm:pb-4 rounded-t-lg bg-white text-gray-900 text-center">
              <p className="text-5xl">
                <span className="font-bold">
                  {formatPrice(priceLevel.hours * discountedPrice(priceLevel))}
                </span>
                {priceLevel.discountPercent && (
                  <span className="ml-1 line-through text-red-500 text-xs sm:text-xl align-top">
                    {formatPrice(priceLevel.hours * pricePerHour)}
                  </span>
                )}
              </p>
              <p className="text-s sm:text-s mt-2">
                {formatPrice(discountedPrice(priceLevel))} per hour
              </p>
              <p className="text-s sm:text-s">
                {priceLevel.discountPercent ? (
                  <>({priceLevel.discountPercent}% off)</>
                ) : (
                  <>&nbsp;</>
                )}
              </p>
            </div>
            <div className="flex flex-row justify-center py-2 sm:py-6 rounded-b-lg bg-pink-600">
              <p className="text-s md:text-xl text-white">
                {priceLevel.hours} {priceLevel.hours > 1 ? 'hours' : 'hour'} of
                audio
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="w-full xs:w-max mx-auto">
        <p className="font-medium text-gray-400 text-center">
          Prices are excluding tax
        </p>
      </div>
    </>
  );
}
