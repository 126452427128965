import React, { ReactElement } from 'react';
import {
  getLanguagesWithCountries,
  LanguageWithCountries,
} from 'lib/languages';
import { GetStaticProps } from 'next';
import Languages from 'components/landing-page/languages';
import Hero from 'components/landing-page/hero';
import SiteHead from 'components/site-head';
import colors from 'tailwindcss/colors';
import HowItWorks from 'components/landing-page/how-it-works';
import Footer from 'components/footer';
import PricingOnly from 'components/landing-page/pricing-only';

interface Props {
  languages: LanguageWithCountries[];
}

export default function Index(props: Props): ReactElement {
  return (
    <>
      <SiteHead />
      <Hero
        navigation={[
          { name: 'Pricing', href: '#pricing' },
          { name: 'How it works', href: '#how-it-works' },
          { name: 'Languages', href: '#languages' },
        ]}
      />
      <div
        id="pricing"
        style={{
          background: `linear-gradient(180deg, white 0%, ${colors.stone['100']} 40%, ${colors.stone['100']} 80%, white 100%)`,
        }}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">
              Pricing
            </h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Pay only for what you need
            </p>
            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
              No monthly charges
            </p>
          </div>
          <PricingOnly />
        </div>
      </div>

      <HowItWorks />
      <Languages languages={props.languages} />
      <Footer />
    </>
  );
}

export const getStaticProps: GetStaticProps<Props> = async () => ({
  props: {
    languages: await getLanguagesWithCountries(),
  },
  revalidate: 24 * 60 * 60, // 24 hours
});
