import React, { Fragment, ReactElement } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import speakerNotesBlack from 'public/speaker_notes_black_48dp.svg';
import showTranscript from 'public/show-transcript-150.png';

export default function Hero(props: {
  navigation: { name: string; href: string }[];
}): ReactElement {
  return (
    <Popover className="bg-white overflow-hidden">
      {({ open }) => (
        <>
          <div className="relative max-w-screen-xl mx-auto flex flex-col lg:flex-row items-center">
            <div className="relative z-10 pb-8 bg-white lg:w-1/2 xl:w-[650px]">
              <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
                <nav
                  className="relative flex items-center justify-between sm:h-10 lg:justify-start"
                  aria-label="Global"
                >
                  <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                    <div className="flex items-center justify-between w-full md:w-auto">
                      <Image
                        className="h-8 w-auto sm:h-10"
                        src={speakerNotesBlack}
                        alt=""
                        layout="fixed"
                        quality={100}
                        priority
                      />
                      <div className="-mr-2 flex items-center md:hidden">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                          <span className="sr-only">Open main menu</span>
                          <MenuIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
                    {props.navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="font-medium text-gray-500 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    ))}
                    <Link href="/transcripts">
                      <a className="font-medium text-blue-600 hover:text-blue-500">
                        Sign in
                      </a>
                    </Link>
                  </div>
                </nav>
              </div>

              <Transition
                show={open}
                as={Fragment}
                enter="duration-150 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel
                  focus
                  static
                  className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                >
                  <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="px-5 pt-4 flex items-center justify-between">
                      <div>
                        <Image
                          className="h-8 w-auto sm:h-10"
                          src={speakerNotesBlack}
                          alt=""
                          layout="fixed"
                          quality={100}
                          priority
                        />
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                          <span className="sr-only">Close main menu</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                    <div className="px-2 pt-2 pb-3 space-y-1">
                      {props.navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                    <Link href="/transcripts">
                      <a className="block w-full px-5 py-3 text-center font-medium text-blue-600 bg-gray-50 hover:bg-gray-100">
                        Sign in
                      </a>
                    </Link>
                  </div>
                </Popover.Panel>
              </Transition>

              <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                <div className="sm:text-center lg:text-left">
                  <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                    <span className="block">TranscribeKit</span>{' '}
                    <span className="block text-blue-600 xl:inline">
                      Speech-to-text in Google&nbsp;Drive™
                    </span>
                  </h1>
                  <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                    Automatically transcribe audio and video directly from
                    Google Drive using our speech-to-text add-on
                  </p>
                  <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                    <div className="rounded-md shadow">
                      {/* eslint-disable-next-line react/jsx-no-target-blank */}
                      <a
                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
                        href="https://workspace.google.com/marketplace/app/transcribekit/831507088910"
                        target="_blank"
                        rel="noopener"
                      >
                        Install add-on
                      </a>
                    </div>
                    <div className="mt-3 sm:mt-0 sm:ml-3">
                      <Link href="/account">
                        <a className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 md:py-4 md:text-lg md:px-10">
                          Account
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </main>
            </div>
            <div className="relative m-3 w-full max-w-[898px] lg:flex-1 h-[525px]">
              <Image
                src={showTranscript}
                alt="Screenshot of the add-on"
                quality={100}
                layout="fill"
                objectFit="cover"
                objectPosition="right"
                placeholder="blur"
                priority
              />
            </div>
          </div>
        </>
      )}
    </Popover>
  );
}
